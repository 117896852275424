import React from "react";
import { ThreeCircles } from "react-loader-spinner";

const Loader = ({
  outerCircleColor = "#242B40",
  innerCircleColor = "#FF9B25",
  middleCircleColor = "#4479FB",
}) => {
  return (
    <ThreeCircles
      height="100"
      width="100"
      color="#4fa94d"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="three-circles-rotating"
      outerCircleColor={outerCircleColor}
      innerCircleColor={innerCircleColor}
      middleCircleColor={middleCircleColor}
      
    />
  );
};

export default Loader;