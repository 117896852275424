import React, { useState, useEffect } from "react";
import { List, Modal, Dropdown, Menu, Tooltip, Spin, Pagination } from "antd";
import { saveAs } from 'file-saver';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllHistory, deleteChat, getConversationHistory } from "../../../../../api/History/history";
import { MoreOutlined, DownloadOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import ChatHistory from '../History/ChatHistory'

const ChatsList = () => {

    const [historyData, setHistoryData] = useState([]);
    const [conversationData, setConversationData] = useState([])
    const [activeConversationId, setActiveConversationId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)
    const [totalPages, setTotalPages] = useState(0);
    const pageLimit = 10;


    // Function to handle switching to the chat history view
    const handleSelectConversation = (id) => {
        setActiveConversationId(id);
    };

    // Function to go back to the chats list
    const handleBackToList = () => {
        setActiveConversationId(null);
    };

    const fetchAllHistory = async () => {
        if(historyData){
            setLoading(true)
        }
        else{
            setInitialLoading(true)
        }
        try {
            const history = await getAllHistory(currentPage, pageLimit);
            setHistoryData(history.data);
            setTotalPages(history.total_pages);
            setPageSize(history.count_per_page)
        } catch (error) {
            console.error('Failed to fetch history:', error);
            setLoading(false)
            // setInitialLoading(false)
        }
        finally {
            setLoading(false); // Set loading to false after data is fetched
            setInitialLoading(false)
        }
    };


    const downloadChat = async (conversationId) => {
        try {
            const convo = await getConversationHistory(conversationId);
            const { email, fullName } = convo.data
            if (convo && convo.success === "true") {
                let chatContent = "";

                convo?.data?.message?.forEach(pair => {
                    for (let i = 0; i < pair.length; i++) {
                        if (i % 2 === 0) {
                            const question = pair[i].content;
                            const answer = pair[i + 1] ? pair[i + 1].content : 'No answer provided';

                            // Format and append the question-answer pair
                            chatContent += `Query By: ${fullName} - ${email},\nQuestion: "${question}",\nAnswer: "${answer}"\n\n\n`;
                        }
                    }
                });
                // Create a Blob from the chat content
                const blob = new Blob([chatContent], { type: "text/plain;charset=utf-8" });

                saveAs(blob, `ChatHistory-${email}.txt`);
            }
        } catch (error) {
            console.error('Failed to download chat', error);
        }
    }

    useEffect(() => {
        fetchAllHistory();
    }, [currentPage]);


    const handleDeleteChat = async (id) => {
        try {
            const response = await deleteChat(id); // Assume deleteChatAPI sends an API request
            if (response.success === "true") {
                // message.success('Chat deleted successfully');
                fetchAllHistory(); // Refresh list after deletion
            } else {
                // message.error('Failed to delete chat');
            }
        } catch (error) {
            //   message.error('Error deleting chat: ' + error.message);
        }
    };


    const showDeleteConfirm = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this chat?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone and will permanently delete the chat history.',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                handleDeleteChat(id);
            },
            onCancel() {
            },
        });
    }

    const menu = (item) => (
        <Menu onClick={(e) => {
            if (e.key === 'delete') {
                showDeleteConfirm(item._id);
            }
            if (e.key === 'download') {
                console.log("hello")
                downloadChat(item.conversationID)
                console.log("Download")
            }
        }}>
            <Menu.Item key="download">
                <div className="flex gap-2 text-nowrap">
                    Download Chat
                    <DownloadOutlined />
                </div>
            </Menu.Item>
            <Menu.Item key="delete">
                <div className="flex gap-2">
                    Delete
                    <DeleteOutlined style={{ color: "red" }} />
                </div>
            </Menu.Item>
        </Menu>
    );

    if (activeConversationId) {
        let isAdminView = activeConversationId ? "true" : "false";
        return <ChatHistory conversationId={activeConversationId} onBack={handleBackToList} isAdminView={isAdminView} />;
    }

    if (initialLoading) {
        return (
            <div className="flex items-center justify-center w-full h-screen">
                <Spin size="medium" />
            </div>

        );
    }
  
    return (
        <div className="flex flex-col items-start w-full h-screen p-4 mt-6 ">
            <div className="w-[95%] h-[90%]">
                {historyData.map((section) => {
                    if (section.data && section.data.length > 0) {
                        return (
                            <React.Fragment key={section.category}>
                                <h3 className="font-medium text-lg md:text-lg xl:text-lg font-poppins p-3">
                                    {section.category}
                                </h3>
                                <List
                                    className="text-black border-gray-200 rounded-lg p-2"
                                    dataSource={section.data}
                                    loading={loading}
                                    renderItem={(item) => (
                                        <List.Item key={item._id}>
                                            <List.Item.Meta
                                                style={{ color: "black", cursor: 'pointer' }}
                                                description={
                                                    <span
                                                        style={{
                                                            color: 'black',
                                                            display: 'inline-block',
                                                            transition: 'transform 0.1s',
                                                            cursor: 'pointer',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            maxWidth: '60vh' // Set your desired max width
                                                        }}
                                                        onMouseEnter={(e) => {
                                                            e.currentTarget.style.transform = 'scale(1.01)';
                                                            e.currentTarget.style.color = "#5b0f8d";
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.currentTarget.style.transform = 'scale(1)';
                                                            e.currentTarget.style.color = "black";
                                                        }}
                                                        
                                                    >
                                                        {item.message[0][0].content}
                                                    </span>
                                                }
                                                onClick={() => handleSelectConversation(item.conversationID)}
                                            />
                                            <div className="desktop-only">
                                                <div className="flex gap-4">
                                                    <Tooltip placement="top" title="Download Chat" >
                                                        <DownloadOutlined onClick={() => downloadChat(item.conversationID)} />
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Delete">
                                                        <DeleteOutlined onClick={() => showDeleteConfirm(item._id)}
                                                            style={{ color: "red" }} />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className="mobile-only">
                                                <Dropdown overlay={() => menu(item)} trigger={['click']}>
                                                    <MoreOutlined style={{ cursor: 'pointer' }} />
                                                </Dropdown>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </React.Fragment>
                        );
                    }
                    return null; // Return null if there's no data
                })}
                <Pagination
                    showSizeChanger={false}
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalPages * pageSize}
                    onChange={setCurrentPage}
                    style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}
                />
            </div>
        </div>
    );
}

export default ChatsList