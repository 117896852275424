import axios from "axios";
import { message } from "antd";

export const dislikeMessageApi = async (
  currentUser,
  currentMessage,
  currentResponse
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/dislikeMessage`,
      {
        email: currentUser.email,
        humanMessage: currentResponse,
        aiMessage: currentMessage,
      },
      {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
        withCredentials: true,
      }
    );
    console.log("Feedback Recorded: Bad Response");
    return true;
    //message.success(response.data)
    //return response.data; // Ensure this is the expected array of users
  } catch (err) {
    message.error(err.response ? err.response.data.error : err.message);
    console.error(err.response ? err.response.data.error : err.message);
    return false;
    // Optionally handle the error e.g., return default data or throw error
    //throw err;
  }
};

export const getDislikesApi = async (
  currentUser,
  setDislikes
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/getDislikes`,
      {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
        withCredentials: true,
      }
    );
    
    setDislikes(response.data.count)
    
    //message.success(response.data)
    //return response.data; // Ensure this is the expected array of users
  } catch (err) {
    message.error(err.response ? err.response.data.error : err.message);
    console.error(err.response ? err.response.data.error : err.message);
    
    // Optionally handle the error e.g., return default data or throw error
    //throw err;
  }
};