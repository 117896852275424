import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./InputSection.css";
import arrowUpIcon from "../../assets/icons/arrow-up-icon.svg";
import clipIcon from "../../assets/icons/clip.svg";
import closeIcon from "../../assets/icons/close.svg";
import arrowRightIcon from "../../assets/icons/arrow-right.svg";
import { Button, message, Modal, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
// api functions for pdf chat
// import pdfChatApi from '../../../../API/pdfChatApi';

const InputSection = ({ sendMessage }) => {
  //initialize hooks
  const [inputValue, setInputValue] = useState("");
  const inputLoader = useSelector((state) => state.loader.inputLoader);

  const handleSetInputValue = (e) => {
    setInputValue(e.target.value);
    e.target.style.height = "20px";
    const totalHeight = e.target.scrollHeight;
    e.target.style.height = `${totalHeight}px`;
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !inputLoader && inputValue !== "") {
      e.preventDefault(); // Prevent the default action to stop from adding a newline

      sendMessage(inputValue);

      setInputValue(""); // Clear the input field
    }
  };

  const isButtonDisabled = inputLoader || inputValue === "";

  return (
    <div className="mx-2 md:mx-5 mb-5 flex flex-col gap-y-2 justify-end relative pb-2">
      <div className="relative flex items-center justify-center rounded-xl shadow min-h-14 max-h-[200px] overflow-y-auto font-poppins md:w-[88%] w-[95%] m-auto input-field">
        <textarea
          placeholder={"Type your prompt here..."}
          value={inputValue}
          onChange={handleSetInputValue}
          onKeyDown={handleEnterKeyPress}
          className="autosize-textarea overflow-y-auto custom-scrollbar focus:outline-none text-textColor text-xs sm:text-sm"
        />

        <button
          onClick={() => {
            if (!isButtonDisabled) {
              sendMessage(inputValue);
            }
            setInputValue("");
          }}
          disabled={isButtonDisabled}
          className={`bg-grey cursor-pointer ${isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
          } 
          mr-5 py-[8px] px-[9px] rounded-lg shadow-lg text-filledBtnText text-lg font-normal`}
        >
          <img className="w-[15px] h-[15px]" src={arrowUpIcon} alt="send" />
        </button>
      </div>
    </div>
  );
};

export default InputSection;
