import React, { useState } from "react";
import { Button } from "antd";
import { InputOTP } from "antd-input-otp";
import { handleVerifyEmail } from "../../../api/Auth/Auth";
import { useDispatch, useSelector } from "react-redux";

const VerifyEmail = ({ setPageMode, setShowFields, setLoading }) => {
  const [value, setValue] = useState([]); // Since the value will be array of string, the default value of state is empty array.
  const user = useSelector((state) => state.user.user);

  const handleFinish = (otp) => {
    const payload = otp || value; // Since useState work asynchronously, we shall add the field value from the autoSubmit.

    const sendOTP = Array.isArray(payload) ? payload.join("") : payload;

    let body = {
      email: user.email,
      otp: sendOTP,
    };
    handleVerifyEmail(body, setPageMode, setShowFields, setLoading);
  };
  return (
    <div className="flex flex-col gap-8 justify-center items-center">
      <InputOTP onChange={setValue} value={value} autoSubmit={handleFinish} />

      <Button onClick={() => handleFinish()}>Submit</Button>
    </div>
  );
};

export default VerifyEmail;
