import React, { useState, useEffect } from "react";
import { Layout, Menu, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import "./SettingsSider.css";
import { setSettingIndex } from "../../../redux/slices/menuSlice";
import BulkUpload from "../SettingsComponents/BulkUpload/BulkUpload";
import UserManagement from "../SettingsComponents/UserManagement/UserManagement";
import ChatsList from "../SettingsComponents/ChatHistory/ChatsList/ChatsList";

const { Sider } = Layout;

const SettingsSider = () => {
  const user = useSelector((state) => state.user.user);
  const settingsIndex = useSelector((state) => state.menu.settingsIndex);
  const dispatch = useDispatch();

  // Initializing Menu Labels
  const menuItems = [
    { label: "Bulk Upload", enabled: true },
    { label: "User Management", enabled: true },
    { label: "Chat History", enabled: true },
    //{ label: "Prompt Modification", enabled: false }
  ];
  return (
    <>
      <Sider
        className="settings-sider-wrapper bg-white z-200 ml-10 "
        width={220}
        breakpoint="lg"
      >
        <div className="sider-header mt-8 text-textColor font-poppins font-medium text-lg md:text-lg xl:text-lg pl-1">
          <span>Settings</span>
        </div>
        <div className="sider-content">
          <Menu
            className="bg-transparent pl-0 text-textSiderDull text-sm font-poppins"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[`item-${settingsIndex}`]}
          >
            {menuItems.map((item, index) => (
              <Tooltip
                title={!item.enabled ? "Coming Soon" : ""}
                placement="right"
                key={`item-${index}`}
              >
                <Menu.Item
                  key={`item-${index}`}
                  onClick={() => {
                    if (item.enabled) dispatch(setSettingIndex(index));
                  }}
                  disabled={!item.enabled}
                >
                  <div className="flex items-center">
                    <div className="w-2 mr-2">
                      {settingsIndex === index && (
                        <div className="h-2 bg-bubble rounded-full"></div>
                      )}
                    </div>
                    <div>{item.label}</div>
                  </div>
                </Menu.Item>
              </Tooltip>
            ))}
          </Menu>
        </div>
      </Sider>

      {settingsIndex === 0 && <BulkUpload />}
      {settingsIndex === 1 && <UserManagement />}
      {settingsIndex === 2 && <ChatsList/>}

    </>
  );
};

export default SettingsSider;
