import React, {useState} from 'react'
import AuthModal from '../../components/AuthModal/AuthModal'


const AuthPage = () => {
    const [modalVisible, setModalVisible] = useState(true);
    const handleCancel = () => {
        setModalVisible(false);
      };

  return (
    <>
 
    <AuthModal visible={modalVisible} onClose={handleCancel} />
    </>
  )
}

export default AuthPage