import React from "react";
import Loader from "./Loader";

const LoaderPage = () => {
  return (
    <Loader
      outerCircleColor="#DFDCF9"
      innerCircleColor="#CCDBF9"
      middleCircleColor="#CAD6F8"
    />
  );
};

export default LoaderPage;