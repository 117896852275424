import axios from "axios";
import { setCurrentUser } from "../../utils/setCurrentUser";
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const checkTokenExpiration = async (accessToken, dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/check-token-expiration`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        withCredentials: true
      }
    );
    console.log("Token Status:", response.data.message);
    return response;
  } catch (error) {
    console.error("Error checking token expiration:", error);
    // If the response is not okay, call the Logout function to perform logout
    if ( error.response.status === 401 || !error.response) {
    
      let userObj = { accessToken: "" };
      setCurrentUser({ dispatch: dispatch, value: userObj });
    }
    throw error;
  }
};
