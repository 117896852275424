import React, { useState } from "react";
import { Space } from "antd";
import crossIcon from "../../../../../assets/icons/cross.png";
import crossBlack from "../../../../../assets/icons/cross-black.png";
import modifyIcon from "../../../../../assets/icons/modify.png";
import modifyBlue from "../../../../../assets/icons/modify-blue.png";
import { removeUserApi } from "../../../../../api/User/Users";

const ActionButtons = ({ record, currentUser, refreshUsers, setLoading, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredModify, setIsHoveredModify] = useState(false);

  const handleRemoveUser = async () => {
    await removeUserApi(currentUser, record.email, setLoading)
    refreshUsers();
  }

  return (
    <Space size="middle">
      <a
        className="flex gap-1 items-center p-2 rounded-md action-modify"
        onMouseEnter={() => setIsHoveredModify(true)}
        onMouseLeave={() => setIsHoveredModify(false)}
        onClick={onClick}
      >
        {isHoveredModify ? (
          <img src={modifyBlue} style={{ width: "18px", height: "18px" }} />
        ) : (
          <img src={modifyIcon} style={{ width: "18px", height: "18px" }} />
        )}
        <p>Modify Roles</p>
      </a>
      <a
        className="flex gap-1 items-center p-2 rounded-md action-remove"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleRemoveUser}
      >
        {isHovered ? (
          <img src={crossIcon} style={{ width: "18px", height: "18px" }} />
        ) : (
          <img src={crossBlack} style={{ width: "18px", height: "18px" }} />
        )}
        <p>Remove User</p>
      </a>
    </Space>
  );
};

export default ActionButtons;
