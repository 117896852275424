import React, { useEffect, useState } from "react";
import { Table, Tag, Avatar } from "antd";
import { editRoleApi, getAllUsersApi } from "../../../../api/User/Users";
import { useDispatch, useSelector } from "react-redux";
import { getUserRole } from "../../../../utils/getUserRole";
import ActionButtons from "./ActionButton/ActionButton";
import ModifyModal from "./ModifyModal/ModifyModal";
const { Column } = Table;

const baseColorList = ["#9C27B0", "#673AB7", "#3F51B5", "#2196F3", "#03A9F4", "#00BCD4", "#009688", "#FF9800", "#FF5722"];
let availableColors = [...baseColorList];

const getRandomColor = () => {
  if (availableColors.length === 0) {
    availableColors = [...baseColorList]; // Reset the list when all colors are used
  }
  const index = Math.floor(Math.random() * availableColors.length);
  return availableColors.splice(index, 1)[0];
};


// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

const UserManagement = () => {
  const user = useSelector((state) => state.user.user);
  const [usersData, setUsersData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [targetUserForRoleModification, setTargetUserForRoleModification] = useState(null);
  const [targetUserRole, setTargetUserRole] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const fetchUsers = async () => {
    const users = await getAllUsersApi(user.email, user.accessToken, setLoading);
    const transformedData = users?.map((user, index) => ({
      key: index.toString(),
      name: user.fullName,
      email: user.email,
      role: getUserRole(user),
      avatarColor: getRandomColor(),
    }));
    setUsersData(transformedData);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const showModal = (user) => {
    setTargetUserForRoleModification(user);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);
    
    await editRoleApi(user, targetUserForRoleModification, targetUserRole, dispatch)
    await fetchUsers();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="flex flex-col items-start w-full h-screen">


      <Table
      //virtual
      loading={loading}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        // scroll={{
        //   x: 2000,
        //   y: 400,
        // }}
        dataSource={usersData}
        className="w-[95%] mt-10 pl-10"
      >
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          className="font-poppins"
          render={(text, record) => (
            <div className="flex items-center gap-2">
              <Avatar
                style={{
                  backgroundColor: record.avatarColor,
                  verticalAlign: "middle",
                }}
                size={38}
              >
                {text[0].toUpperCase()}
              </Avatar>
              <div className="flex flex-col">
                <p className="text-[14px] font-medium">{text}</p>
                <p className="text-[11px]" style={{ color: "gray" }}>
                  {record.email}
                </p>
              </div>
            </div>
          )}
        />

        <Column
          dataIndex="role"
          title="User Role"
          key="role"
          className="font-poppins"
          render={(text, record) => (
            <Tag color={text=="Admin" ? "red": "blue"} key={text}>
              {text}
            </Tag>
          )}
        />
        <Column
          className="font-poppins"
          title="Actions"
          key="action"
          render={(_, record) => (
            <ActionButtons
              record={record}
              currentUser={user}
              refreshUsers={fetchUsers}
              setLoading={setLoading}
              onClick={() => showModal(record)}
            />
          )}
        />
      </Table>
      <ModifyModal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} targetUser={targetUserForRoleModification} setTargetUserRole={setTargetUserRole}/>
    </div>
    
  );
};

export default UserManagement;
