import React, { useState } from "react";
import { Layout } from "antd";
import SettingsSider from "./SettingsSider/SettingsSider";

export default function Settings() {
  const [collapsible, setCollapsible] = useState(false);
  return (
    <>
      <Layout className="bg-mainBg overflow-hidden relative">
        <SettingsSider />
      </Layout>
    </>
  );
}
