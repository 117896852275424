import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";

import { getDislikesApi } from "../../api/Feedback/Feedback";
import { getAllUsersApi } from "../../api/User/Users";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import usersIcon from "../../assets/icons/users-icon.png";
import feedbackIcon from "../../assets/icons/feedback-icon.png";
import adminIcon from "../../assets/icons/admin-icon.png";

const Analytics = () => {
  const [dislikes, setDislikes] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [activeAdmins, setActiveAdmins] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.user);

  const handleFeedback = async () => {
    await getDislikesApi(user, setDislikes);
  };

  const handleUsers = async () => {
    const users = await getAllUsersApi(
      user.email,
      user.accessToken,
      setLoading
    );
    const adminCount = users.filter((user) => user.role.isAdmin).length;

    setActiveUsers(users.length);
    setActiveAdmins(adminCount);
  };

  useEffect(() => {
    handleUsers();
    handleFeedback();
  }, []);

  return (
    <div className="w-full mt-20 ml-10 mr-5 md:mx-20 lg::mx-40 justify-center">
      <Row gutter={[16, 16]} className="w-full flex flex-col sm:flex-row items-center md:items-start">
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card bordered={true}>
            <span className="flex items-center justify-between">
              <p className="font-poppins text-md text-analyticsTitle">
                Active Users
              </p>
              <img src={usersIcon} style={{ width: "24px" }} />
            </span>
            <CountUp
              end={activeUsers}
              duration={1.5}
              className="font-poppin text-xl"
            />
          </Card>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card bordered={true}>
            <span className="flex items-center justify-between">
              <p className="font-poppins text-md text-analyticsTitle">
                Feedback
              </p>
              <img src={feedbackIcon} style={{ width: "24px" }} />
            </span>
            <CountUp
              end={dislikes}
              duration={1.5}
              className="font-poppin text-xl"
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
          <Card bordered={true}>    
            <span className="flex items-center justify-between">
            <p className="font-poppins text-md text-analyticsTitle">
              Active Admins
            </p>
              <img src={adminIcon} style={{ width: "24px" }} />
            </span>
            <CountUp
              end={activeAdmins}
              duration={1.5}
              className="font-poppin text-xl"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Analytics;
