import React, { useState, useEffect } from "react";
import Modal from "antd/es/modal/Modal";
import { Avatar, Radio } from "antd";


const ModifyModal = ({ visible, onOk, onCancel, targetUser, setTargetUserRole }) => {
  const [value, setValue] = useState(targetUser?.role === 'Admin' ? 1 : 2);

  useEffect(() => {
    setValue(targetUser?.role === 'Admin' ? 1 : 2);
  }, [targetUser]);

  const onChange = (e) => {
    setValue(e.target.value);
    setTargetUserRole(e.target.value);
  };

  return (
    <Modal visible={visible} onOk={onOk} onCancel={onCancel} width={460} okText={"Submit"}>
      {/* You can add form here to modify the role */}
      <div className="flex items-start gap-2">
        <Avatar
          style={{
            backgroundColor: targetUser?.avatarColor,
            verticalAlign: "middle",
          }}
          size={40}
        >
          {targetUser?.name[0].toUpperCase()}
        </Avatar>
        <div className="flex flex-col font-poppin gap-4">
          <div>
            <p className="main-text text-[16px] font-medium tracking-wide">
              {targetUser?.name}
            </p>
            <p className="sub-text text-[12px] tracking-wide text">
              {targetUser?.email}
            </p>
          </div>
          <div>
            <p className="font-poppins font-semibold">Role</p>
            <Radio.Group onChange={onChange} value={value} >
              <Radio value={1} className="font-poppins">Admin</Radio>
              <Radio value={2} className="font-poppins">User</Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModifyModal;
