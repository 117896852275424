import axios from "axios";
import { message } from "antd";

export const bulkUploadApi = async (formData, setFileList, accessToken) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + "/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${accessToken}`,
        },
        withCredentials: true,
      }
    );
    if (response.status === 200) {
      message.success(response.data.Message);
      setFileList([]);
    } else {
      // Handle non-200 responses
      message.error("Some files were not uploaded successfully.");
    }
  } catch (error) {
    console.error("Upload failed", error);
    message.error("File upload failed");
  }
};
