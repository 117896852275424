import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { handleSignUp } from "../../../api/Auth/Auth";
import { Button } from "antd";

const SignUp = ({ setPageMode, setLoading }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const handleSignUpClick = (e) => {
    e.preventDefault();
    const body = {
      name: name,
      email: email,
      password: password,
    };
    handleSignUp(body, dispatch, setPageMode, setLoading);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        handleSignUpClick(e);
    }
};  

  return (
    <>
      <div className="mb-3">
        <label
          className="block text-gray-700 text-sm font-bold mb-1"
          htmlFor="name"
        >
          Full Name
        </label>
        <input
          type="text"
          id="name"
          placeholder="Enter your Full Name"
          className="w-[300px] border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-300 animate-slide-up"
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="mb-3">
        <label
          className="block text-gray-700 text-sm font-bold mb-1"
          htmlFor="email"
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          placeholder="Enter your email"
          className="w-[300px] border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-300 animate-slide-up"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="mb-3">
        <label
          className="block text-gray-700 text-sm font-bold mb-1"
          htmlFor="password"
        >
          Password
        </label>
        <input
          type="password"
          id="password"
          placeholder="Enter your password"
          className="w-[300px] border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-300 animate-slide-up"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </div>

      <div className="flex justify-center mt-6">
        <Button
          className="auth-modal-btn text-black px-10 h-10 bg-orange-400 font-poppins"
          onClick={handleSignUpClick}
        >
          Sign Up
        </Button>
      </div>
    </>
  );
};

export default SignUp;
