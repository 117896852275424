import React from "react";
import { Button } from "antd";
import "./UploadButton.css";

const UploadButton = ({ fileList, onClick }) => {
  return (
    <Button
      className="upload-btn font-poppins"
      type="primary"
      onClick={onClick}
      disabled={!fileList.length}
    >
      Start Upload
    </Button>
  );
};

export default UploadButton;
