import { createSlice } from "@reduxjs/toolkit";
import io from "socket.io-client";

export const socketIoSlice = createSlice({
  name: "socketIo",
  initialState: {
    socketIo: null,
    isConnected: false,
  },

  reducers: {
    setSocket(state, action) {
      state.socketIo = action.payload;
    },
    setConnected(state, action) {
      state.isConnected = action.payload;
    },
    clearSocket(state) {
      state.socketIo = null;
      state.isConnected = false;
    },
  },
});

export const { setSocket, setConnected, clearSocket } = socketIoSlice.actions
export const selectSocketIo = (state) => state.socketIo.socketIo;


export const initializeSocket = () => (dispatch) => {
  const socket = io(`${process.env.REACT_APP_BACKEND_URL}/chat`, {
    transports: ["websocket"],
  });
  dispatch(setSocket(socket));

  socket.on("connect", () => {
    dispatch(setConnected(true));
    console.log("Socket connected.");
  });

  socket.on("disconnect", () => {
    dispatch(setConnected(false));
    console.log("Socket disconnected.");
  });
};

export const disconnectSocket = () => (dispatch, getState) => {
  const { socketIo } = getState().socketIo;
  if (socketIo) {
    socketIo.emit("end_session");

    socketIo.once("session_ended", () => {
      socketIo.disconnect();
      dispatch(clearSocket());
      console.log("Socket disconnected after ending session.");
    });
  }
};

export default socketIoSlice.reducer;
