import axios from "axios";
import { message } from "antd";
import { setCurrentUser } from "../../utils/setCurrentUser";
import { setMenuIndex } from "../../redux/slices/menuSlice";

export const getAllUsersApi = async (currentUser, accessToken, setLoading) => {
  setLoading(true);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/getAllUsers`,
      { currentUser },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true,
      }
    );
    setLoading(false);
    return response.data; // Ensure this is the expected array of users
  } catch (err) {
    console.error(err.response ? err.response.data.error : err.message);
    setLoading(false);
    // Optionally handle the error e.g., return default data or throw error
    //throw err;
  }
};

export const removeUserApi = async (currentUser, targetUser, setLoading) => {
  setLoading(true);
  let body = {
    currentUser: currentUser.email,
    targetUser: targetUser,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/removeUser`,
      { body },
      {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
        withCredentials: true,
      }
    );

    message.success(response.data.message);
    setLoading(false);
    return response.data;
  } catch (err) {
    setLoading(false);
    message.error(err.response ? err.response.data.error : err.message);
    console.error(err.response ? err.response.data.error : err.message);
    // Optionally handle the error e.g., return default data or throw error
    //throw err;
  }
};

export const editRoleApi = async (
  currentUser,
  targetUser,
  targetUserRole,
  dispatch
) => {
  let url = "";
  switch (targetUserRole) {
    case 1:
      url = `${process.env.REACT_APP_BACKEND_URL}/addAdmin`;
      break;
    case 2:
      url = `${process.env.REACT_APP_BACKEND_URL}/removeAdmin`;
    default:
      break;
  }

  let body = {
    currentUser: currentUser.email,
    targetUser: targetUser.email,
  };

  try {
    const response = await axios.post(
      url,
      { body },
      {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
        withCredentials: true,
      }
    );

    if (currentUser.email == response.data.email) {
      let role = {
        isAdmin: response.data["role.isAdmin"],
        isUser: response.data["role.isUser"],
      };

      let userObj = {
        email: response.data.email,
        role: role,
      };

      setCurrentUser({ dispatch: dispatch, value: userObj });
      dispatch(setMenuIndex(0));
    }
    message.success(response.data.message);
    return response.data;
    
  } catch (err) {
    message.error(err.response ? err.response.data.error : err.message);
    // Optionally handle the error e.g., return default data or throw error
    //throw err;
  }
};
