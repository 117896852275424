import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    menuIndex: -1,
    settingsIndex: 0
  };

  export const menuSlice = createSlice({
    name: "menuSelected",
    initialState,
    reducers: {
      setMenuIndex: (state, action) => {
        state.menuIndex = action.payload;
      },
      setSettingIndex: (state, action) => {
        state.settingsIndex = action.payload;
      }
    },
  });
  
  
  export const { 
    setMenuIndex,
    setSettingIndex
  } = menuSlice.actions;
  
  export default menuSlice.reducer;