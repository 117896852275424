import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageLoader: false,
  inputLoader: false
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setPageLoader: (state, action) => {
      state.pageLoader = action.payload.pageLoader;
    },
    clearPageLoader: (state) => {
      state.pageLoader = initialState.pageLoader;
    },
    setInputLoader: (state, action) => {
      state.inputLoader = action.payload.inputLoader;
    },
    clearInputLoader: (state) => {
      state.inputLoader = initialState.inputLoader;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPageLoader,
  clearPageLoader,
  setInputLoader,
  clearInputLoader
} = loaderSlice.actions;

export default loaderSlice.reducer;
