import React, { useState } from "react";
import axios from "axios";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Button } from "antd";
import "./BulkUpload.css";
import FileCard from "./FileCard/FileCard";
import UploadButton from "./UploadButton/UploadButton";
import { bulkUploadApi } from "../../../../api/BulkUpload/BulkUpload";
import { useSelector } from "react-redux";

const { Dragger } = Upload;

const BulkUpload = () => {
  const [fileList, setFileList] = useState([]);
  const user = useSelector((state) => state.user.user);

  const handleDelete = (fileToDelete) => {
    const newFileList = fileList.filter(file => file.uid !== fileToDelete.uid);
    setFileList(newFileList);
    message.success(`${fileToDelete.name} has been deleted.`);
  };

  const props = {
    name: "file",
    multiple: true,
    accept: ".csv, .pdf",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      // Update the state only when the files are being added
      setFileList(info.fileList.filter((file) => !!file.status));
    },
    customRequest({ file, onSuccess, onError, onProgress }) {
      // You can perform actual upload here if needed or just simulate the upload
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    showUploadList: false
  };

  // Function to handle file upload to dummy endpoint
  const handleUpload = async () => {
    message.success("Files queued for upload ⏳");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files", file.originFileObj);
    });

    await bulkUploadApi(formData, setFileList, user.accessToken)
  };


  const isFileListEmpty = fileList.length === 0;

  return (
    <div className={`bulk-upload-container flex gap-6 items-center w-full ${isFileListEmpty ? 'justify-center' : ''}`}>
      <div className="bg-mainBg overflow-hidden relative flex flex-col justify-center w-[60%] items-center gap-4">
        <Dragger className="w-[100%]" {...props} fileList={fileList}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ color: "#5B0F8D" }} />
          </p>
          <p className="ant-upload-text font-poppins">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint font-poppins">
            Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files.
          </p>
        </Dragger>
        <UploadButton fileList={fileList} onClick={() => handleUpload()} />
      </div>
      { !isFileListEmpty && (
        <div className="w-[35%] h-[85%] rounded-md flex bg-siderBg justify-start flex-col items-center pt-10 pb-10 gap-2 overflow-y-auto shadow">
          {fileList.map((file) => (
            <FileCard
              key={file.uid}
              fileName={file.name}
              onDelete={() => handleDelete(file)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BulkUpload;
