import React, { useState } from "react";
import { Modal, Button } from "antd";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { handleGoogleSignIn } from "../../api/Auth/Auth";
import LoaderPage from "../Common/Loader/LoaderPage";
import { useNavigate } from "react-router-dom";
import SignUp from "./AuthSubComponents/SignUp";
import SignIn from "./AuthSubComponents/SignIn";
import VerifyEmail from "./AuthSubComponents/VerifyEmail";

const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;


const AuthModal = ({ visible, onClose }) => {
  const [showFields, setShowFields] = useState(false);
  const [pageMode, setPageMode] = useState("Login");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleContinueWithEmail = () => {
    setShowFields(true);
  };

  const handleLoginMode = () => {
    setPageMode("Login");
  };

  const handleSignUpMode = () => {
    setPageMode("SignUp");
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        footer={null}
        className="w-full max-w-md"
      >
        {loading ? (
          <div
            style={{
              backdropFilter: "blur(5px)",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              position: "absolute",
              zIndex: "4000",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "30vh",
              }}
            >
              <LoaderPage />
            </div>
          </div>
        ) : null}
        <div className="flex flex-col items-center px-0 py-6 font-poppins">
          <div className="auth-header flex flex-col items-center">
            <h1 className="text-2xl font-bold mb-4 text-black">
              {pageMode == "Login"
                ? "Login"
                : pageMode == "Verify"
                ? "Verification"
                : "Welcome"}
            </h1>

            <p className="text-sm text-gray-600 mb-4">
              {pageMode == "Login"
                ? "New here?"
                : pageMode == "Verify"
                ? "Verify your email via OTP"
                : "Already have an account?"}{" "}
              {pageMode == "SignUp" && (
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={handleLoginMode}
                >
                  Login
                </span>
              )}
              {pageMode == "Login" && (
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={handleSignUpMode}
                >
                  Create an account
                </span>
              )}
            </p>

            <div className="mt-3 w-[100%] ">
              <span className="flex items-center justify-center gap-2">
                <hr
                  className={`${
                    pageMode === "SignUp" ? "w-[10%]" : "w-[20%]"
                  } border-slate-500`}
                ></hr>
                <p
                  className={`text-slate-900 text-xs flex text-center ${
                    pageMode == "Verify" ? "cursor-pointer" : ""
                  }`}
                  onClick={() => {
                    if (pageMode === "Verify") {
                      setPageMode("SignUp");
                    }
                  }}
                >
                  {pageMode == "Login"
                    ? "Log in"
                    : pageMode == "Verify"
                    ? "Go back"
                    : "Or create a free account"}
                </p>
                <hr
                  className={`${
                    pageMode === "SignUp" ? "w-[10%]" : "w-[20%]"
                  } border-slate-500`}
                ></hr>
              </span>
            </div>
          </div>

          {pageMode !== "Verify" && (
            <div className="mt-7">
              <GoogleOAuthProvider clientId="608974967092-6ck7f75ors3jr2lmk8tmqevmnvu86d2p.apps.googleusercontent.com">
                <GoogleLogin
                  text="continue_with"
                  width={"300px"}
                  logo_alignment="center"
                  onSuccess={(credentialResponse) => {
                    const decoded = jwtDecode(credentialResponse.credential);
                    const { email, given_name, family_name } = decoded;

                    const full_name = `${given_name} ${family_name}`;
                    console.log(decoded)
                    const body = {
                      email: email,
                      user_name: full_name,
                    };
                    handleGoogleSignIn(body, navigate, dispatch, setLoading);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </GoogleOAuthProvider>
            </div>
          )}

          {!showFields && (
            <Button
              className="auth-modal-btn w-[300px] h-10 mb-4 mt-5"
              onClick={handleContinueWithEmail}
            >
              Continue with Email
            </Button>
          )}

          {showFields && (
            <div className="mt-10 animate-slideUp">
              {pageMode == "SignUp" && <SignUp setPageMode={setPageMode} setLoading={setLoading} />}
              {pageMode == "Login" && <SignIn onClose={onClose} setLoading={setLoading} />}
              {pageMode == "Verify" && (
                <VerifyEmail
                  setPageMode={setPageMode}
                  setShowFields={setShowFields}
                  setLoading={setLoading}
                />
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AuthModal;
