// Imports
import { Layout, message } from "antd";
import { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ChatBot.css";
import Header from "../Header/Header";
import Messages from "../Messages/Messages";
import InputSection from "../Input/InputSection";
import {
  selectSocketIo,
  initializeSocket,
  disconnectSocket,
} from "../../redux/slices/socketioSlice";
import {
  setCurrentMessage,
  setCurrentResponse
} from "../../redux/slices/chatbotStatesSlice";
import {
  setInputLoader
} from "../../redux/slices/loaderSlice";
import { setUser } from "../../redux/slices/userSlice";

// Imports End

// const socket = io(`${process.env.REACT_APP_BACKEND_URL}/chat`, {
//   transports: ["websocket"], // Ensure WebSocket is used
//   path: "/socket.io", // Default path, adjust if your server uses a different one
// });

export const ChatBot = () => {
  /*  Initialize States */
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [streamMessage, setStreamMessage] = useState("");
  const [room_id, setRoomId] = useState(null);
  const [user_id, setUserId] = useState(null)
  const chatWindowRef = useRef(null);
  const socket = useSelector(selectSocketIo);
  const userDetails = useSelector(state => state.user.user)
  console.log("User Details", userDetails)
  const dispatch = useDispatch();


  /* Socket Initialization and Cleanup */
  useEffect(() => {
    dispatch(initializeSocket());

    return () => {
      dispatch(disconnectSocket());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!socket) return;

    const handleStream = (msg) => {
      if (msg.isFinal) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { msg: streamMessage + msg.data, sender: "bot" },
        ]);
        setStreamMessage("");
        dispatch(setCurrentResponse({ currentResponse: streamMessage + msg.data }))
        dispatch(setInputLoader({ inputLoader: false }));
      } else {
        setStreamMessage((prevStreamMessage) => prevStreamMessage + msg.data);
      }
    };

    socket.on("room_assigned", (data) => {
      setRoomId(data.room_id);
      //console.log("Room assigned:", data.room_id);
    });

    socket.on("stream", handleStream);

    // Cleanup on component unmount
    return () => {
      socket.off("room_assigned");
      socket.off("stream", handleStream);
    };
  }, [socket, streamMessage]);

  /* Handles SendMessage */
  const sendMessage = (msg) => {
    setLoading(true);
    dispatch(setInputLoader({ inputLoader: true }));  // Used to disable the Input

    setMessages((prevMessages) => [
      ...prevMessages,
      { msg: msg, sender: "User" },
    ]);

    // Ensure userDetails.id is passed correctly
    const userId = userDetails ? userDetails.id : null;
    if (!userId) {
      console.error("User ID is undefined");
      setLoading(false);
      return; // Early return if user ID is not available
    }
    console.log(userId)

    socket.emit("start_stream", { query_text: msg, user_id: userId });
    dispatch(setCurrentMessage({ currentMessage: msg }));
    setLoading(false);
  };

  return (
    <Layout className="min-h-screen max-h-[20px] relative z-10">
      <>
        <Layout className={`flex flex-col h-full w-full bg-overlayBg`}>
          <Header label1={"Ambassador AI"} label2={" | Chat"} />
          <>
            <div
              ref={chatWindowRef}
              className="min-h-[71vh] max-h-[20px] overflow-y-scroll md:w-[85%] w-[95%] m-auto"
            >
              <Messages
                sendMessage={sendMessage}
                messages={messages}
                chatWindowRef={chatWindowRef}
                loading={loading}
                streamMessage={streamMessage}
              />
            </div>
            <InputSection sendMessage={sendMessage} />
          </>
        </Layout>
      </>
    </Layout>
  );
};
