import React, { useState, useEffect, useRef } from "react";
// import "../../../ChatBot/ChatBot.css";
import Header from "../../../../Header/Header";
import Messages from "../../../../Messages/Messages";
import { Layout, message } from "antd";
import { getConversationHistory } from "../../../../../api/History/history"
import { ArrowLeftOutlined } from "@ant-design/icons";

const ChatHistory = ({ conversationId, onBack, isAdminView  }) => {
  const [conversationHistory, setConversationHistory] = useState([])
  const [sendMessages, setSendMessages] = useState([]);
  const [streamMessages, setStreamMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  

  const fetchConversationHistory = async () => {
    setLoading(true);
    try {
        const response = await getConversationHistory(conversationId);
        const history = response.data;
        const { email, fullName } = history;
        const sendMsgs = [];
        const streamMsgs = [];
        const allMessages = [];

        history.message.forEach((pair) => {
            pair.forEach((message, index) => {
                const msg = {
                    msg: message.content,
                    sender: message.tag,
                    email: email,
                    fullName: fullName
                 
                };
                allMessages.push(msg);
                if (index % 2 === 0) {  
                    sendMsgs.push(msg);
                } else {  
                    streamMsgs.push(msg);
                }
            });
        });
        setSendMessages(sendMsgs);
        setStreamMessages(streamMsgs);
        setMessages(allMessages);
    } catch (error) {
        console.error('Failed to fetch conversation history:', error);
    } finally {
        setLoading(false);
    }
}
useEffect(() => {
  if (!isAdminView) {
    // Perform actions specific to user-query scenario
  }
  fetchConversationHistory();
}, [conversationId, isAdminView]);

  const chatWindowRef = useRef(null);
  return (
    <Layout className="min-h-screen max-h-[20px] relative z-10">
      <>
        <Layout className={`flex flex-col h-full w-full bg-overlayBg`}>
        <div className="flex gap-2">
        <ArrowLeftOutlined onClick={()=> onBack() } style={{ marginTop: "30px", }}/>
          <Header label1={"Chat History"} />
        
          </div>
          <>
            <div
              ref={chatWindowRef}
              className="min-h-[71vh] max-h-[20px] overflow-y-scroll md:w-[85%] w-[95%] m-10"
            >
              <Messages
              isAdminView={isAdminView}
                sendMessage={sendMessages}
                messages={messages}
                chatWindowRef={chatWindowRef}
                loading={loading}
                streamMessage={isAdminView ? null : streamMessages} // Stream messages not shown in admin view
              />
            </div>
          </>
        </Layout>
      </>
    </Layout>
  )

}

export default ChatHistory