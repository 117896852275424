import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { handleSignIn } from "../../../api/Auth/Auth";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";

const SignIn = ({ onClose, setLoading }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignInClick = (e) => {
    e.preventDefault();
    const body = {
      email: email,
      password: password,
    };
    handleSignIn(body, dispatch, navigate, onClose, setLoading);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignInClick(e);
    }
  };
  return (
    <>
      <div className="mb-3">
        <label
          className="block text-gray-700 text-sm font-bold mb-1"
          htmlFor="email"
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          placeholder="Enter your email"
          className="w-[300px] border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-300 animate-slide-up"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="mb-3">
        <label
          className="block text-gray-700 text-sm font-bold mb-1"
          htmlFor="password"
        >
          Password
        </label>
        <input
          type="password"
          id="password"
          placeholder="Enter your password"
          className="w-[300px] border rounded-md px-3 py-2 focus:outline-none focus:ring focus:border-blue-300 animate-slide-up"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="flex justify-center mt-6">
        <Button
          className="auth-modal-btn text-black px-10 h-10 bg-orange-400 font-poppins"
          onClick={handleSignInClick}
        >
          Log in
        </Button>
      </div>
    </>
  );
};

export default SignIn;
