import { current } from "@reduxjs/toolkit";

const apiUrl = process.env.REACT_APP_BACKEND_URL;


export const getHistory = async (email,token) => {
    try {
      const response = await fetch(`${apiUrl}/getHistory?email=${email}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to fetch conversation history");
      }
      return data;
    } catch (error) {
      console.error("Error fetching conversation history:", error);
      throw error;
    }
  }

// Get All Chat History
export const getAllHistory = async(currentPage,totalPages) => {
  try {
    const response = await fetch(`${apiUrl}/getHistory?current_page=${currentPage}&page_size=${totalPages}`, {
      method: "GET",
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Failed to fetch conversation history");
    }
    return data;
  } catch (error) {
    console.error("Error fetching conversation history:", error);
    throw error;
  }
}

// Get Single Conversation History
export const getConversationHistory = async(conversationId) => {
  try {
     const response = await fetch(`${apiUrl}/getConvoHistory/${conversationId}`, {
      method: "GET",
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Failed to retrieve conversation history");
    }
    return data;
  } catch (error) {
    console.error("Error retrieving conversation history:", error);
    throw error
  }
}

// Delete Chat History
export const deleteChat = async(id) => {
  try {
     const response = await fetch(`${apiUrl}/deleteChat/${id}`, {
      method: "DELETE",
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
    console.log("Response",response)
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Failed to delete chat history");
    }
    return data;
  } catch (error) {
    console.error("Error deleting conversation history:", error);
    throw error
  }
}