import axios from "axios";
import { message } from "antd";
import { setLoader } from "../../utils/setLoader";
import { setCurrentUser } from "../../utils/setCurrentUser";
import { setMenuIndex } from "../../redux/slices/menuSlice";

export const handleSignUp = (body, dispatch, setPageMode, setLoading) => {
  setLoading(true);
  axios
    .post(process.env.REACT_APP_BACKEND_URL + "/signup", {
      details: body,
    })
    .then((res) => {
      message.success(res.data.success);

      let userObj = {
        name: body.name,
        email: body.email,
      };

      setCurrentUser({ dispatch: dispatch, value: userObj });
      setPageMode("Verify");
      setLoading(false);
    })
    .catch((err) => {
      console.log(err.response.data.error);
      message.error(err.response.data.error);
      setLoading(false);
    });
};

export const handleSignIn = (body, dispatch, navigate, onClose, setLoading) => {
  setLoading(true);

  axios
    .post(process.env.REACT_APP_BACKEND_URL + "/signin", {
      details: body,
    })
    .then((res) => {
      console.log(res.data);
      let userObj = {
        id: res.data.user._id,
        email: res.data.user.email,
        name: res.data.user.fullName,
        isVerified: res.data.user.isVerified,
        role: res.data.user.role,
        accessToken: res.data.access_token,
      };

      setCurrentUser({ dispatch: dispatch, value: userObj });
      message.success(`Login Successful. Welcome 🚀`);
      navigate("/");
      dispatch(setMenuIndex(0));
      setLoading(false);
      onClose();
    })
    .catch((err) => {
      setLoading(false);
      message.error(err.response.data.error);
    });
};

export const handleGoogleSignIn = (body, navigate, dispatch, setLoading) => {
  setLoading(true);
  axios
    .post(process.env.REACT_APP_BACKEND_URL + "/googleSignIn", {
      details: body,
    })
    .then((res) => {
      console.log(res.data);
      let userObj = {
        id: res.data.user._id,
        email: res.data.user.email,
        name: res.data.user.fullName,
        isVerified: res.data.user.isVerified,
        role: res.data.role,
        accessToken: res.data.access_token,
        //loggedInWithGoogle: res.data.user.loggedInWithGoogle,
      };
      setCurrentUser({ dispatch: dispatch, value: userObj });
      navigate("/");

      dispatch(setMenuIndex(0));
      setLoading(false);
      message.success(`Login Successful. Welcome 🚀`);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
      message.error(err.response.data.error);
    });
};

export const handleLogout = (dispatch, accessToken) => {
  axios
    .post(
      process.env.REACT_APP_BACKEND_URL + "/logout",
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((res) => {
      let userObj = {
        accessToken: "",
      };
      setCurrentUser({ dispatch: dispatch, value: userObj });
      message.success(res.data.message);
    })
    .catch((err) => {
      message.error(err.response.data.error);
    });
};

export const handleVerifyEmail = (
  body,
  setPageMode,
  setShowFields,
  setLoading
) => {
  setLoading(true);
  axios
    .post(process.env.REACT_APP_BACKEND_URL + "/verifyotp", {
      details: body,
    })
    .then((res) => {
      message.success(res.data.message);
      setShowFields(true);
      setPageMode("Login");
      setLoading(false);
    })
    .catch((err) => {
      message.error(err.response.data.error);
      setLoading(false);
    });
};
