import React, { useState, useEffect } from "react";
import AntSider from "../../components/AntSider/AntSider";
import { Layout } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { checkTokenExpiration } from "../../api/Token/TokenExpiration"; // Import the function
import { ChatBot } from "../../components/ChatBot/ChatBot";

const Home = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.user.user.accessToken);
  const [collapsible, setCollapsible] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await checkTokenExpiration(accessToken, dispatch);
        console.log("Token status:", response.data.message);
        // You can perform any necessary action based on the token status here
      } catch (error) {
        console.error("Error checking token expiration:", error);
        // Handle error as needed
      }
    };

    checkToken();
  }, []);

  return (
    <Layout className="min-h-screen bg-mainBg overflow-hidden relative">
      <>
        <AntSider
          collapsibleState={collapsible}
          setCollapsible={setCollapsible}
        />
      </>
    </Layout>
  );
};

export default Home;
