import React, { useState, useEffect } from "react";
import { Layout, Menu, Avatar, Dropdown } from "antd";
import "./AntSider.css";
import { ChatBot } from "../ChatBot/ChatBot";
import Settings from "../Settings/Settings";

import NYULogo from "../../assets/images/NYULogo.png";

// For Light Theme
import generateGray from "../../assets/images/chatBlack.png";
import generateFilled from "../../assets/images/chatBlack.png";
import settingsFilled from "../../assets/images/settingsBlack.png";
import settingsGray from "../../assets/images/settingsBlack.png";
import collapsibleIcon from "../../assets/icons/collapse-icon.svg";
import logoutIcon from "../../assets/icons/logoutIcon.png";
import analyticsIcon from "../../assets/icons/analytics-icon.png";

import { useSelector, useDispatch } from "react-redux";
import { setMenuIndex } from "../../redux/slices/menuSlice";
import { handleLogout } from "../../api/Auth/Auth";
import { getUserRole } from "../../utils/getUserRole";
import Analytics from "../Analytics/Analytics";

const { Sider } = Layout;

const AntSider = ({ collapsibleState, setCollapsible }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  const avatarName = user.name ? user.name[0].toUpperCase() : "";

  const menuIndex = useSelector((state) => state.menu.menuIndex);

  const [color, setColor] = useState("");
  const ColorList = ["#7265e6"];

  // Icon Initialization

  const iconsGrey = [generateGray, analyticsIcon, settingsGray];
  const iconsFilled = [generateFilled, analyticsIcon, settingsFilled];

  // Initializing Menu Labels
  const menuLabels = ["Chat"];

  if (getUserRole(user) === "Admin") {
    menuLabels.push("Analytics")
    menuLabels.push("Settings");
  }
  const footerMenu = (
    <Menu className="flex flex-col gap-2">
      <Menu.Item key="email" disabled>
        <span className="menu-item-email">{user.email}</span>
      </Menu.Item>
      <hr />
      <Menu.Item
        key="logout"
        icon={
          <img
            src={logoutIcon}
            style={{
              width: "28px",
              height: "28px",
            }}
          />
        }
        onClick={handleUserLogout}
      >
        Log out
      </Menu.Item>
    </Menu>
  );

  const updateRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * ColorList.length);
    setColor(ColorList[randomIndex]);
  };

  useEffect(() => {
    updateRandomColor();
  }, [user.name]);

  function handleUserLogout() {
    handleLogout(dispatch, user.accessToken);
  }

  return (
    <>
      <Sider
        className="sider-wrapper bg-siderBg"
        width={240}
        collapsedWidth="0"
        breakpoint="md"
        style={{ zIndex: 1000 }}
        onBreakpoint={(broken) => {
          setCollapsible(broken);
        }}
        onCollapse={(collapsed, type) => {
          setCollapsible(collapsed);
        }}
       
        collapsible
        trigger={
          <div
            className={`${
              collapsibleState ? "justify-center" : "justify-end pe-4"
            } bg-transparent flex items-center hover:brightness-50 transition-all duration-300`}
          >
            <img
              src={collapsibleIcon}
              style={{ height: "12px", width: "12px" }}
              alt="collapsible"
              className={`w-[11px] transition-all duration-300 ${
                collapsibleState ? "scale-x-[-1]" : ""
              }`}
            />
          </div>
        }
      >
        <div className="sider-content">
          {!collapsibleState ? (
            <div className="w-full flex text-white items-center h-[5.3rem] pt-2 pl-7">
              <img
                src={NYULogo}
                style={{ width: "110px", height: "40px" }}
                alt="NYU"
              />
            </div>
          ) : (
            <div className="collapse-icon w-full flex items-center h-[5.3rem] rounded-t-xl"></div>
          )}
          <Menu
            className="bg-transparent pl-0 text-textSiderDull text-sm  font-poppins"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[`item-${menuIndex}`]}
          >
            {menuLabels.map((label, index) => (
              <Menu.Item
                key={`item-${index}`}
                icon={
                  (collapsibleState || menuIndex === index) && (
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={
                        menuIndex === index
                          ? iconsFilled[index]
                          : iconsGrey[index]
                      }
                      alt={label}
                    />
                  )
                }
                onClick={() => {
                  dispatch(setMenuIndex(index));
                }}
              >
                {label}
              </Menu.Item>
            ))}
          </Menu>
        </div>
        <div className="sider-footer">
          <div className="pb-10 sider-bottom">
            <Dropdown
              className="sider-dropdown"
              overlay={footerMenu}
              placement="topCenter"
              arrow
              trigger={["click"]}
            >
              <div
                className={`flex items-center gap-2 cursor-pointer 
              ${collapsibleState ? "pl-[28%]" : "pl-7"}`}
              >
                <Avatar
                  style={{
                    backgroundColor: color,
                    verticalAlign: "middle",
                  }}
                  size={33}
                >
                  {avatarName}
                </Avatar>
                {!collapsibleState && (
                  <p className="font-poppins text-textColor text-sm">
                    {user.name}
                  </p>
                )}
              </div>
            </Dropdown>
          </div>
        </div>
      </Sider>
      {menuIndex === 0 && <ChatBot />}
      {menuIndex === 1 && getUserRole(user) === "Admin" && <Analytics />}
      {menuIndex === 2 && getUserRole(user) === "Admin" && <Settings />}
      
    </>
  );
};

export default AntSider;
